.id-camera {
    width: 100%;
    height: 100%;
}

.camera {
    height: 300px;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* Laptop, Desktop, TV */
@media (orientation: landscape) {
    .faceHintContainer {
        position: relative;
        right: 0;
        left: 0;
        margin: auto;
        z-index: 100;
    }
}
/* Mobile devices and Tablets */
@media (orientation: portrait) {
    .faceHintContainer {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 100px;
        margin: auto;
        z-index: 100;
    }
}